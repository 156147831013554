import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames';
import { Provider } from 'react-redux';
// import { ConnectedProkseeViewer } from '../proksee-viewer/app/ProkseeViewer';
import { ProkseeViewer } from '../proksee-viewer/app/ProkseeViewer';
import Message from '../proksee-viewer/presenters/Message';
import store from '../proksee-viewer/app/store'
import '../proksee-viewer/index.css';
import './ProkseeViewerWrapper.css';


// ProkseeEvents may be needed to connect events between the rails app and ProkseeViewer
// class ProkseeEvents extends React.Component {
//
//   constructor(props) {
//     super(props);
//     // Register events for monitoring
//     // const prokseeEvents = window.prokseeEvents;
//     // prokseeEvents.on('THEME-CHANGE', (value) => store.dispatch(updateTheme(value)) );
//   }
//
//   render() {
//     return <div></div>
//   }
//
// }

class ProkseeViewerWrapper extends React.Component {

  constructor(props) {
    super(props);
    this.state = { initialize: true, mapLoaded: false };
    setTimeout( () => {
      this.setState({ initialize: false })
    }, 500);
    this.showProksee = this.showProksee.bind(this);
    this.mapLoadedHandler = this.mapLoadedHandler.bind(this);
  }

  static propTypes = {
    userRole: PropTypes.oneOf(['admin', 'tester', '']),
    loggedIn: PropTypes.bool,
  }

  mapLoadedHandler() {
    Message.close();
    this.setState( {mapLoaded: true} );
  }

  showProksee() {
    const { jsonURL, projectID, railsEnv, userRole, loggedIn } = this.props;
    return (
      <Provider store={store}>
        <ProkseeViewer
          jsonURL={jsonURL}
          projectID={projectID}
          railsEnv={railsEnv}
          theme='none'
          mapLoadedHandler={this.mapLoadedHandler}
          userRole={userRole}
          loggedIn={loggedIn}
        />
      </Provider>
    )
  }

  render() {
    // const { jsonURL } = this.props;
        // {/* <ProkseeEvents /> */}
        // {/* <ConnectedProkseeViewer jsonURL={jsonURL} theme='none' /> */}
      // </Provider>
    const wrapperClass = classNames(
      'ProkseeViewerWrapper',
      {'map-loaded': this.state.mapLoaded}
    );
    return (
      <div>
        <Message content="Loading Map..." status="open" dimBackground={false} />
        <div className={wrapperClass} >
          { this.state.initialize || this.showProksee() }
        </div>
      </div>
    );
  }

}
export default ProkseeViewerWrapper;

// loadMapFromJSON(json, msg) {
//   var cgv = this.cgv;
//   this._loadStart(msg);
//   setTimeout(() => {
//     cgv.io.loadJSON(json.cgview);
//     this.sidebar.reload();
//     // FIXME: THIS SHOULD BE IN HEADER BAR
//     $('#map-name').html(cgv.name || 'Click to name map');
//     cgv.drawFull();
//     this._loadEnd();
//   }, 300);
// }
//
// _loadStart(msg = 'The map is loading...') {
//   this.$page.fadeTo(100, 0);
//   Sidebar.showMessage(msg);
// }
//
// _loadEnd() {
//   setTimeout(() => {
//     Sidebar.hideMessage();
//     this.$page.fadeTo(300, 1);
//   }, 300);
// }
// static showMessage(msg, duration=300) {
//   $('#processing-message #message').html(msg);
//   $('#processing-message').fadeIn(duration);
// }
//
// static hideMessage(duration=300) {
//   $('#processing-message').fadeOut(duration);
// }


