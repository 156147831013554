import React from 'react';
import PropTypes from 'prop-types';
import './ProkseeViewer.css';
import ServerAPI from '../models/ServerAPI';
import HelpAPI from '../models/HelpAPI';
import Sidebar from './Sidebar';
import { ConnectedProjectName } from '../containers/ProjectName';
import { ConnectedProjectBadgeController } from '../containers/ProjectBadgeController';
// import { ConnectedProjectBadge } from '../containers/ProjectBadge';
// import { ConnectedSessionProjectBadge } from '../containers/SessionProjectBadge';
// import SaveButton from '../containers/SaveButton';
import ConnectedSaveButton from '../containers/SaveButton';
// import Button from '../presenters/Button';
import ImageButton from '../presenters/ImageButton';
import { ConnectedMainTabContainer } from './MainTabContainer';
import Toast from '../presenters/Toast';
import Tooltip from '../presenters/Tooltip';
import Message from '../presenters/Message';
import { CGViewContext } from './CGViewContext';
import { initializedCGViewDispatchEvents } from './CGViewDispatch';
import { initializedProkseeServerDispatchEvents } from './ProkseeServerDispatch';
import { ConnectedSnapshotsAddDialog } from '../panes/dialogs/SnapshotsAddDialog';
import ProkseeEvents from './ProkseeEvents';
import classNames from 'classnames';
import logo from '../images/cgview_logo.png';
import * as helpers from '../support/Helpers';
// Connected
// import { connect } from 'react-redux';


// The ProkseeViewer Component will fill the available space of the containing element.

class ProkseeViewer extends React.Component {

  static propTypes = {
    cgvDivID: PropTypes.string,
    theme: PropTypes.string,
    mapLoadedHandler: PropTypes.func,
    jsonURL: PropTypes.string,
    projectID: PropTypes.string,
    railsEnv: PropTypes.string,
    userRole: PropTypes.oneOf(['admin', 'tester', '']),
    loggedIn: PropTypes.bool,
  }

  static defaultProps = {
    cgvDivID: 'cgv-container',
    theme: 'light',
    mapLoadedHandler: () => {},
    loggedIn: false,
  }

  constructor(props) {
    super(props);
    this.cgvDivID = props.cgvDivID;

    this.loadCGView = this.loadCGView.bind(this);
    this.onSidebarToggle = this.onSidebarToggle.bind(this);
    this.setSidebarRef = this.setSidebarRef.bind(this);
    this.setMainTabContainerRef = this.setMainTabContainerRef.bind(this);

    // Initialize the ServerAPI with the project ID
    const Server = new ServerAPI(props.projectID);
    // Initialize the HelpAPI
    const Help = new HelpAPI();

    this.state = {
      userRole: props.userRole,
      loggedIn: props.loggedIn,
      cgv: null,
      cgvDivID: this.cgvDivID,
      cgvLoadHandler: this.loadCGView,
      // Close sidebar if window is tool narrow
      sidebarClosed: (window.innerWidth < 576),
      projectID: props.projectID,
      // Snapshots
      showAddSnapshotDialog: false,
      // sidebarRef: React.createRef(),
      // tabsRef: React.createRef(),
    };
  }

  // componentDidMount() {
  //   console.log('didMount', this.state)
  //   initializedCGViewDispatchEvents(this.state.cgv, this.state.sidebarRef)
  // }


  setSidebarRef(ref) {
    // this.setState({sidebarRef: ref},
    //   () => initializedCGViewDispatchEvents(this.state.cgv, ref)
    // );
    this.setState({sidebarRef: ref},
      () => {
        initializedCGViewDispatchEvents(this.state.cgv, ref);
        initializedProkseeServerDispatchEvents(this.state.projectID);
      }
    );

  }

  setMainTabContainerRef(ref) {
    // this.setState({tabsRef: ref});
    this.setState({tabsRef: ref},
      () => {
        // Could add events to state if needed in the future
        // For now simply initializing it will add the events
        new ProkseeEvents(this.state.cgv, ref)
      }
    );
  }


  loadCGView(cgv) {
    const { jsonURL, mapLoadedHandler, railsEnv } = this.props;
    this.setState({
      cgv: cgv
    });

    // console.log('load', this.state)
    window.cgv = cgv;
    // This is now done when the Sidebar is initialized
    // initializedCGViewDispatchEvents(cgv, this.state.sidebarRef);
    cgv.trigger('viewer-update', { attributes: {pixelRatio: cgv.canvas.pixelRatio }});

    const Server = new ServerAPI();
    Server.get(jsonURL, undefined, {cache: 'no-cache'})
    .then( response => {
      if (response.ok) {
        cgv.io.loadJSON(response.json);
        cgv.centerLine.update(cgv.centerLine.toJSON());
        // This 1 ms wait time is to ensure the map is finished being resized.
        setTimeout( mapLoadedHandler, 1)
        setTimeout( () => cgv.resize(), 10)

        // Confirm leaving page if CGView data has changed
        window.onbeforeunload = function(e) {
          return (cgv.dataHasChanged && railsEnv === 'production') || undefined;
        }

      }
    })

    // cgv.debug = { sections: ['time'] };
    cgv.draw();

    // TEST ADDING LOTS OF FEATURES
    // this.addTestFeatures(cgv, 50000);
  }

  onSidebarToggle(open) {
    this.setState({
      sidebarClosed: !open
    });
  }

  onAddSnapshot() {
  }

  onClickAddSnapshot() {
    console.log('click')
    this.setState({showAddSnapshotDialog: true});
  }

  renderAddSnapshotDialog() {
    const { showAddSnapshotDialog } = this.state;
    if (showAddSnapshotDialog) {
      return (
        <ConnectedSnapshotsAddDialog
          onClose={() => this.setState({showAddSnapshotDialog: false})}
        />
      );
    }
  }

  renderSnapshotButton() {
    return (
      <ImageButton
        className='btn-snapshot-add'
        imageName='snapshotAdd'
        text='Snapshot'
        size='small'
        width={80}
        onClick={() => this.onClickAddSnapshot()}
        title='Take a reloadable snapshot of the current map state.'
      />
    );
  }

  // renderSessionProjectBadge() {
  //   const daysRemaining = 1;
  //   const daysLeftSting = (daysRemaining === 1) ? '1 day left!' : `${daysRemaining} days left`;
  //   if (true) {
  //     return (
  //       <ImageButton
  //         className='session-project-badge'
  //         imageName='alert'
  //         text={
  //           <div className='session-button-text'>
  //             <div className='session-button-head'>Session Project</div>
  //             <div className='session-button-sub'>{daysLeftSting}</div>
  //           </div>}
  //         width={110} onClick={ () => {} }
  //         tip='This is a session project and will be deleted 7 days after the last save date.'
  //       />
  //     );
  //   }
  // }

  render() {
    const {name, theme} = this.props;
    // const { sidebarRef } = this.state;

    const mainClass = classNames(
      'Main',
      {'sidebar-closed': this.state.sidebarClosed}
    );
    const headerClass = classNames(
      'main-header',
      {'sidebar-closed': this.state.sidebarClosed}
    );

    // console.log(theme)
    if (theme != 'none') {
      helpers.changeTheme(theme)
    }
    // This message normally goes right below Toast
    // But it is now in ProkseeViewerWrapper
    // <Message content="Loading Map..." status="open" />
    return (
      <CGViewContext.Provider value={this.state}>
        <div>
          <div className="ProkseeViewer">
            <div className={mainClass} >
              <div className={headerClass}>
                <ConnectedProjectBadgeController />
                <ConnectedProjectName value={name} className='map-name' />
                <ConnectedSaveButton />
              </div>
              <ConnectedMainTabContainer ref={this.setMainTabContainerRef} divID={this.cgvDivID} cgvHandler={this.loadCGView} className='MainTabContainer' tabBarExtraContent={this.renderSnapshotButton()} />
            </div>
            <Sidebar ref={this.setSidebarRef} closed={this.state.sidebarClosed} onToggle={this.onSidebarToggle} />
          </div>
          <Toast />
          <Tooltip  />
          <div className='color-picker' />
        </div>
        {this.renderAddSnapshotDialog()}
      </CGViewContext.Provider>
    );
  }
}

// Connected
// const prokseeViewerMapStateToProps = (state) => ({ theme: state.ui.theme });
// const ConnectedProkseeViewer = connect(prokseeViewerMapStateToProps)(ProkseeViewer);

// export default ProkseeViewer;
// export { ProkseeViewer, ConnectedProkseeViewer }
export { ProkseeViewer }

  // addTestFeatures(cgv, count) {
  //   if (!cgv || !count) { return; }
  //   let featureData = [];
  //   const fLength = Math.ceil(cgv.sequence.length / count / 2.5);
  //   for (let i = 0; i < count; i++) {
  //     const start = fLength * i * 2;
  //     const stop = fLength * (i*2 + 1);
  //     featureData.push({ name: `name-${i}`, legend: 'CDS', start, stop, source: 'sequence-features' });
  //   }
  //   cgv.addFeatures(featureData);
  //   // TODO: this should be in cgview.js
  //   cgv.tracks().forEach( (t) => t.refresh() );
  // }
