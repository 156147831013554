export default function add({inputs, mapData}) {

  // Filter mapData based on DialogAdd inputs

  const regionType = 'PHASTEST';

  if (!inputs.add_prophage) {
    mapData.features = mapData.features.filter( f => f.type !== regionType);
  }
  if (!inputs.add_genes) {
    // Genes will have type of "PHASTEST - TYPE"
    mapData.features = mapData.features.filter( f => f.type === regionType);
  }

  return { ok: true, mapData };
}

