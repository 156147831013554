import React from 'react';
import DataCard from '../../../cards/DataCard';
import { VirtualGrid, Column } from '../../../presenters/VirtualGrid';
// import ExternalLink from '../../../presenters/ExternalLink';
// import TextElement from '../../../presenters/TextElement';
// import DataElement from '../../../presenters/DataElement';
// import DataElementGroup from '../../../presenters/DataElementGroup';
// import ImageButton from '../../../presenters/ImageButton';
import classNames from 'classnames';
// import './phastest.css';

class PhastestDetailsDataCard extends DataCard {

  get extractType() {
    return 'json';
  }

  renderData(detailsJSON) {
    console.log(detailsJSON);

    // const header = ['Region', 'Location', 'Name', 'E-Value'];
    const header = ['Region', 'Location', 'E-Value', 'Name'];
    const data = [];
    detailsJSON.forEach((d, i) => {
      data.push([
        d.region_index,
        `${d.start} - ${d.stop}`,
        d['e-value'],
        d.name,
      ]);
    });

    return (
      <VirtualGrid
        data={data}
        header={header}
        alternateRowColors
        rowHeight={30}
        defaultColumnWidth={80}
      >
        <Column index={0} width={80} type='number' />
        <Column index={1} width={170} />
        <Column index={2} width={120} type='number' />
        <Column index={3} width={700} />
      </VirtualGrid>
    );
  }
}

export default PhastestDetailsDataCard;
