import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import './SaveButton.css';
import classNames from 'classnames';
import ServerAPI from '../models/ServerAPI';
import Button from '../presenters/Button';
import Message from '../presenters/Message';
import { CGViewContext } from '../app/CGViewContext';
import { resetSnapshots } from '../actions/snapshots';
import * as helpers from '../support/Helpers';
// Connected
import { connect, useDispatch } from 'react-redux';

SaveButton.propTypes = {
  // saveURL: PropTypes.string,
  className: PropTypes.string,
  dataHasChanged: PropTypes.bool,
}

SaveButton.defaultProps = {
  dataHasChanged: false,
}

function SaveButton(props) {
  const context = useContext(CGViewContext);
  const cgv = context.cgv;
  const loggedIn = context.loggedIn;
  const dispatch = useDispatch();
  // const projectID = context.projectID;

  function saveClicked(name) {
    // TODO: authenication
    Message.open({content:'Saving...'});

    const data = { data: JSON.stringify(cgv.io.toJSON()) };
    const Server = new ServerAPI();
    Server.post(Server.URL.projectSave, data)
    .then( response => {
      if (response.ok) {
        Message.close();
        cgv.update({dataHasChanged: false});
        dispatch(resetSnapshots());
        // Send plausible event
        // console.log('REDACTED URL:', helpers.redactedURL())
        plausible('map-save-changes', { u: helpers.redactedURL(), props: {method: 'HTTP', logged_in: loggedIn.toString()}});
      } else {
        // FIXME: have some error handling here!
      }
    });
  }

  const {
    className,
    dataHasChanged,
  } = props;

  // console.log(dataHasChanged);

  const elementClass = classNames(
    'SaveButton', className
  );

  const tip = (dataHasChanged) ? 'Save Updated Map to Server' : 'No Changes to Save';
  const label = (dataHasChanged) ? 'Save Changes' : 'Saved';
  const width = (dataHasChanged) ? 100 : 70;

  return (
    <Button
      disabled={!dataHasChanged}
      title={tip}
      className={elementClass}
      width={width}
      onClick={ () => saveClicked() }>{label}</Button>
  )
      // width={60}
      // tip={tip}
      // tipPosition='left'
}

// TODO: this should become connected to show that the map has unsaved changes.
//   - It might be best to wait for the undo/redo functionality
//   - Then we can plug into that
//Connected
const saveButtonMapStateToProps = (state) => ({ dataHasChanged: state.viewer.dataHasChanged });
const ConnectedSaveButton = connect(saveButtonMapStateToProps)(SaveButton);

// export default SaveButton;
export default ConnectedSaveButton;



