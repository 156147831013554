import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ServerAPI from '../models/ServerAPI';
import './DataCard.css';
import ImageButton from '../presenters/ImageButton';
import ExternalLink from '../presenters/ExternalLink';
import {FilesCard} from './FilesCard';
import Tools from '../models/Tools';
import Papa from 'papaparse'
import { CGViewContext } from '../app/CGViewContext';
import {ConnectedAddJobResultsButton} from '../containers/AddJobResultsButton';
import * as helpers from '../support/Helpers';

// TODO: Create New sub class called ReportCard
// - This will take all the report card stuff from here
// - Add new "report" section in yaml with
//   - featuredFiles, helpfulLinks
// - The report data will be provided to the ReportCards

// Extend DataCard to show Output Cards for a Job.
// DataCard will download the Job Output file and provide it to renderData.
// Data can be extracted as text or as CSV (Array of Arrays).
// TODO (as needed):
// - types of pdf and html would be helpful
// - Mulitple data files for one DataCard
//   - data from yaml can optionally be a hash
//     - data: {file1: 'path/to/file1', file2: 'path/to/file2', ...}
//   - In this case extractType and extractProps can also return hash to handle different types for each file
//     - extractType = () => {file1: 'text', file2: 'csv'}
class DataCard extends React.Component {

  static propTypes = {
    // id: PropTypes.number.isRequired,
    job: PropTypes.object.isRequired,
    jobCards: PropTypes.object,
    output: PropTypes.shape({
      // path to the data for this job
      data: PropTypes.string,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: 'Downloading...',
      status: 'waiting', // 'waiting', 'downloading', 'failed', 'complete'
    }
  }

  // Get the CGView object of the current map
  get cgv() {
    return this.context.cgv;
  }


  // TODO: The following "open" methods would be better if they were called from the "job" instead of DataCard

  // Open the data card with the given key
  openDataCard(key) {
    const { jobCards } = this.props;
    jobCards.setTabByKey(key);
  }

  // Open the file viewer with the procided paths
  openFiles(paths=[], options={}) {
    const { jobCards } = this.props;
    jobCards.openFiles(paths, options);
  }

  // Open the log card
  openLog() {
    this.openDataCard('log')
  }

  // Download file for path
  downloadFile(path) {
    const { job } = this.props;
    FilesCard.downloadJobFile(job, {path})
  }

  textExtract(data) {
    return data;
  }

  jsonExtract(data) {
    return JSON.parse(data);
  }

  csvExtract(data) {
    const defaultProps = {
      skipEmptyLines: true,
      comments: '#',
      error: (error, file) => {console.log(error)},
    }

    const csv = Papa.parse(data, {
      skipEmptyLines: true,
      comments: '#',
      error: (error, file) => {console.log(error)},
      ...this.extractProps,
    });
    return csv.data;
  }


  extractData(data) {
    return this[`${this.extractType}Extract`](data);
  }

  getData() {
    const { job, output } = this.props;
    if (output.embed) {
      // console.log('EMBEDDDDDDDDING')
      const Server = new ServerAPI();
      const dataPath = encodeURIComponent(output.data);
      const url = `${Server.embeddedJobFile(job)}?path=${dataPath}`;
      const data = (
        <object id="inlineFrameExample_CSS_ME"
          width="100%"
          height="100%"
          data={url}>
        </object>
      )
      const status = 'complete';
      this.setState({data, status});
    } else {
      this.downloadData();
    }
  }
      // data="/projects/578e59d6-3dad-4fad-af98-da8f84862b81/jobs/202/embedded_file.html?key=root-2-13-10">

  downloadData() {
    const { job, output, extractType } = this.props;
    const dataPath = output.data;

    const Server = new ServerAPI();
    const offset = 0;
    const downloadType = 'all';

    Server.get(Server.jobFile(job), {path: dataPath, offset, downloadType})
    .then( response => {
      let data;
      if (response.ok) {
        const fileData = response.json.data;
        if (fileData === 'FILE NOT FOUND') {
          status = 'failed';
          data = fileData;
        } else {
          status = 'complete';
          data = this.extractData(fileData);
        }
      } else {
        status = 'failed';
        data = 'There was a problem downloading the data.'
      }
      this.setState({data, status});
    });
  }

  // Return the inline URL image src for a path in the job working directory
  imageSrcFor(path) {
    const { job } = this.props;
    const Server = new ServerAPI();
    const encodedPath = encodeURIComponent(path);
    const url = `${Server.embeddedJobFile(job)}?path=${encodedPath}`;
    return url;
  }

  // position: 'top' or 'bottom' - NOT IMPLEMENTED YET
  renderCaption(caption, position='bottom') {
    return (
      <div className='card-caption'>
        {caption}
      </div>
    );
  }

  renderImageForPath(path) {
    const url = this.imageSrcFor(path);
    return (
      <img className='card-image' src={url} />
    );
  }

  // Show the data file and its path used for this data card
  renderFooter() {
    const { output } = this.props;
    const dataPath = output.data;

    const filePath = `ROOT/${dataPath}`;

    const footerButtons = (
        <div className='footer-btns'>
          <ImageButton imageName='file' size='small' title='View File' onClick={() => this.openFiles([filePath], {viewerPosition: 'full'})} />
          <ImageButton imageName='download' size='small' title='Download File' onClick={() => this.downloadFile(dataPath)} />
        </div>
    );

    return (
      <div className='data-card-footer'>Data Source: <span className='data-source-path'>{dataPath}</span>{footerButtons}</div>
    );
  }

  // Override to use different extraction type
  get extractType() {
    return 'text';
  }

  // Override to add props for extraction
  get extractProps() {
    return {};
  }

  // Render an alert box with the msg
  // TODO: need alert types and width
  renderAlert(msg) {
    return (
      <div style={{padding: '5px'}}>
        <div className='report-alert more-width'>
          {msg}
        </div>
      </div>
    );
  }

  // renderAddResultsButton() {
  //   const { job } = this.props;
  //   return (
  //     <div className='btn-add-job-results'>
  //       <ConnectedAddJobResultsButton id={job.id} text='Add Features to Map' width={170} />
  //     </div>
  //   );
  // }
  //
  // renderReportHeader(title) {
  //   const { job } = this.props;
  //   const tool = Tools.get(job.tool.publicID);
  //   const headerTitle = title || `${tool.name} Report`;
  //   return (
  //     <div className='report-header'>
  //       {headerTitle}
  //     </div>
  //   );
  // }
  //
  // renderMapResultsTable(mapData) {
  //   const { job } = this.props;
  //   const tool = Tools.get(job.tool.publicID);
  //   const comma = helpers.commaNumber;
  //   const featureCount = mapData && mapData.features && mapData.features.length;
  //
  //   return (
  //     <table className='p-table more-width'>
  //       <thead>
  //         <tr className='table-title'><th colSpan='2'>{tool.name} Results</th></tr>
  //       </thead>
  //       <tbody>
  //         <tr><td className='first-column'>Features found</td><td className='right'><strong>{comma(featureCount)}</strong></td></tr>
  //         <tr><td colSpan='2'>{this.renderAddResultsButton()}</td></tr>
  //       </tbody>
  //     </table>
  //   );
  // }
  //
  // // Returns a button for file with the provided path
  // renderFeaturedFile(path, description) {
  //   const { job } = this.props;
  //
  //   const fileName = path.split('/').pop();
  //   const displayPath = path.replace('ROOT/', '');
  //
  //   const tool = Tools.get(job.tool.publicID);
  //   // Find first output that uses this path (skipping reports)
  //   const output = tool && tool.dataCardOutputs().find( out => (out.data === displayPath && out.id !== 'report'));
  //
  //   const dataCardBtn = output && (
  //     <ImageButton text='Data Card' imageName='dataCard' width={100}
  //       onClick={() => {this.openDataCard(output.id)}}
  //     />
  //   );
  //   return (
  //     <div className='featured-file'>
  //       <div className='file-name'>{fileName}</div>
  //       <div className='path'>{displayPath}</div>
  //       <div className='description'>{description}</div>
  //       <div className='file-action-buttons'>
  //         <ImageButton text='View File' imageName='file' width={100}
  //           onClick={() => {this.openFiles([path], {viewerPosition: 'full'})}}
  //         />
  //         <ImageButton text='Download' imageName='download' width={100}
  //           onClick={() => {this.downloadFile(displayPath)}}
  //         />
  //         {dataCardBtn}
  //       </div>
  //     </div>
  //   );
  // }
  //
  // // fileList: array of objects with 2 keys:
  // //  - path (e.g. output_Prokka/prokka.gff)
  // //  - descritpion
  // renderFeaturedFilesTable(fileList) {
  //   const featuresFilesHeader = (
  //     <div className='featured-files-header'>
  //       <div>Featured Files</div>
  //       <ImageButton text='All Files' imageName='file' width={90} className='btn-all-files'
  //         onClick={() => {this.openFiles([], {viewerPosition: 'bottom'})}}
  //       />
  //     </div>
  //   );
  //   const rows = fileList.map( f => <tr key={f.path}><td>{this.renderFeaturedFile(`ROOT/${f.path}`, f.description)}</td></tr> );
  //   return (
  //     <table className='p-table more-width featured-files-table'>
  //       <thead><tr className='table-title'><th>{featuresFilesHeader}</th></tr></thead>
  //       <tbody>{rows}</tbody>
  //     </table>
  //   );
  // }
  //
  // // links: array of obejcts with 2 keys:
  // // - url
  // // - name
  // renderHelpfulLinks(links) {
  //   const { output } = this.props;
  //   const rows = links.map( l => <tr key={l.name}><td><ExternalLink name={l.name} link={l.url} size={12} /></td></tr> );
  //   return (
  //     <table className='p-table more-width'>
  //       <thead><tr className='table-title'><th>Helpful Links</th></tr></thead>
  //       <tbody>{rows}</tbody>
  //     </table>
  //   );
  // }


  // Override with method to diplay data in output card
  renderData(data) {
  }

  // Centers 'data' message in DataCard
  renderEmptyData(data) {
    return <div className='data-empty'>{data}</div>;
  }

  render() {
    const { job } = this.props;
    const { data, status } = this.state;
    // console.log(status, data)

    if (job.finalized && status === 'waiting') {
      this.getData();
    }

    let contents;
    try {
      contents = (status === 'complete') ? this.renderData(data) : this.renderEmptyData(data);
    } catch(exception) {
      contents = this.renderEmptyData("This DataCard could not be displayed");
      console.error(exception);
    }

    return (
      <div className='DataCard'>
        {contents}
        {this.renderFooter()}
      </div>
    );
  }

}

DataCard.contextType = CGViewContext;

//Connected
// const dataCardMapStateToProps = (state, ownProps) => ({ job: state.jobs.byID[ownProps.id] });
// const ConnectedDataCard = connect(dataCardMapStateToProps, null, null, {forwardRef: true})(DataCard);

export default DataCard;
// export default ConnectedDataCard;

